<template>
  <WithLocationSummaries v-slot="{ locations }">
    <VContainer v-if="locations.length" class="menu" fluid pa-0 grid-list-xl>
      <WithLocation
        v-slot="{ isLocationLoading, theLocation }"
        :slug="locations[0].slug"
      >
        <BaseLocationMenus v-if="!isLocationLoading" :location="theLocation" />
      </WithLocation>
    </VContainer>
  </WithLocationSummaries>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BlueOakHsvMenu',
  components: {},
  props: {
    menus: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('menus', ['areChangesSaved', 'getEntitySet', 'getEntity']),
    showSnackbar: {
      get() {
        return !this.areChangesSaved
      },
      set() {
        // just chill
      },
    },
  },
}
</script>

<style lang="scss">
@import '@design';

.menu {
  width: 100%;
  max-width: 1440px;
}
</style>
